.navbar {
    height: 6rem;
    background-color: #21212146;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;

}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: 'BestermindRegular';
    color: var(--primary-2);
    font-size: 2.5rem;
    user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    backdrop-filter: blur(20px) !important;
}




@media (max-width:1100px) {
    .navbar--container{
        padding: 0 2rem;
    }
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}
.nav-link-wrapper {
    margin-bottom: 1rem; 
}
@media (max-width: 768px) {
    .mobile-menu {
      display: none;
    }
    .mobile-menu2 {
        display: block;
      }
  }
  
  @media (min-width: 769px) {
    .mobile-menu {
      display: block;
      margin-right: 40rem;
      margin-left: 3rem;
    }
    .mobile-menu2 {
        display: none;
      }
  }
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}