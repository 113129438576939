/**
* Template Name: Gp
* Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
* Updated: Jun 02 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
  
:root {
    --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway", sans-serif;
    --nav-font: "Poppins", sans-serif;
  }
  
  /* Global Colors */
  :root {
    /* Background Color - This color is applied to the background of the entire website as well as individual sections. */
    --background-color: #ffffff;
  
    /* Default Color - This is the default color used for the majority of the text content. */
    --default-color: #444444;
  
    /* Heading Color - This color is used for titles, headings and secondary elements. */
    --heading-color: #151515;
  
    /* Accent Color - This is the main accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out. */
    --accent-color: #ffc451;
  
    /* Contrast Color - This is a color used for text when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors. */
    --contrast-color: #312f2f;
  }
  
  /* Nav Menu Colors */
  :root {
    /* Nav Color - This is the default color of the main navmenu links. */
    --nav-color: rgba(255, 255, 255, 0.905);
  
    /* Nav Hover Color - This color is applied to main navmenu links when they are hovered over. */
    --nav-hover-color: #ffc451;
  
    /* Nav Dropdown Background Color - This color is used as the background for dropdown boxes that appear when hovering over primary navigation items. */
    --nav-dropdown-background-color: #ffffff;
  
    /* Nav Dropdown Color - This color is used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-color: #212529;
  
    /* Nav Dropdown Hover Color - Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
    --nav-dropdown-hover-color: #ffc451;
  }
  
  /* Template Custom Colors */
  :root {
    --footer-background-color: #000000;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    --background-color: rgba(0, 0, 0, 0.8);
    --heading-color: #ffffff;
    --contrast-color: #ffffff;
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 10px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 36px;
    margin-right: 8px;
  }
  
  .header .logo h1 {
    font-size: 32px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
  }
  
  .header .logo span {
    color: var(--accent-color);
    font-size: 32px;
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: var(--contrast-color);
    font-size: 14px;
    padding: 8px 30px;
    margin: 0 0 0 30px;
    border-radius: 4px;
    transition: 0.3s;
    border: 2px solid var(--accent-color);
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--default-color);
    background: var(--accent-color);
  }
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 15px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  /* Index Page Header
  ------------------------------*/
  .index-page .header {
    --background-color: rgba(0, 0, 0, 0);
  }
  
  /* Index Page Header on Scroll
  ------------------------------*/
  .index-page.scrolled .header {
    --background-color: rgba(0, 0, 0, 0.8);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Navmenu - Desktop */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      padding: 18px 15px;
      font-size: 16px;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:last-child a {
      padding-right: 0;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 0;
      z-index: 99;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      background-color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Navmenu - Mobile */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-dropdown-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 90%);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), white 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: color-mix(in srgb, var(--accent-color), white 90%);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      background-color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    --background-color: var(--footer-background-color);
    --default-color: #ffffff;
    --heading-color: #ffffff;
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    position: relative;
  }
  
  .footer .footer-top {
    padding: 50px 0;
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 10%);
  }
  
  .footer .footer-about .logo {
    line-height: 1;
    margin-bottom: 10px;
  }
  
  .footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .footer .footer-about .logo span {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--heading-font);
    color: var(--heading-color);
  }
  
  .footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
  }
  
  .footer .social-links a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    background-color: color-mix(in srgb, var(--default-color) 5%, white 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul i {
    margin-right: 4px;
    font-size: 12px;
    line-height: 0;
    color: var(--accent-color);
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: var(--accent-color);
  }
  
  .footer .footer-contact p {
    margin-bottom: 5px;
  }
  
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
    padding: 6px 8px;
    position: relative;
    border-radius: 4px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
    display: flex;
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
    transition: 0.3s;
  }
  
  .footer .footer-newsletter .newsletter-form:focus-within {
    border-color: var(--accent-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email] {
    border: 0;
    padding: 4px;
    width: 100%;
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
    color: var(--default-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: -7px -8px -7px 0;
    background: var(--accent-color);
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .footer .footer-newsletter .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-top: 10px;
  }
  
  .footer .footer-newsletter .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: subscription-loading 1s linear infinite;
  }
  
  @keyframes subscription-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .footer .copyright {
    padding: 30px 0;
  }
  
  .footer .copyright p {
    margin-bottom: 0;
  }
  
  .footer .credits {
    margin-top: 5px;
    font-size: 13px;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: #000;
    transition: all 0.6s ease-out;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    position: relative;
  }
  
  .page-title .heading {
    padding: 80px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .page-title .heading h1 {
    font-size: 38px;
    font-weight: 700;
  }
  
  .page-title nav {
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 20px 0;
  }
  
  .page-title nav ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .page-title nav ol li+li {
    padding-left: 10px;
  }
  
  .page-title nav ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 80px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 58px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: var(--accent-color);
    margin: 4px 10px;
  }
  
  .section-title p {
    color: var(--heading-color);
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    --default-color: #ffffff;
    --background-color: #000000;
    --heading-color: #ffffff;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 120px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .hero .container {
    position: relative;
    z-index: 3;
  }
  
  .hero h2 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    font-family: var(--nav-font);
  }
  
  .hero h2 span {
    color: var(--accent-color);
  }
  
  .hero p {
    margin: 10px 0 0 0;
    font-size: 24px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: var(--accent-color);
  }
  
  .hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
  }
  
  .hero .icon-box h3 a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: ease-in-out 0.3s;
  }
  
  .hero .icon-box:hover {
    border-color: var(--accent-color);
  }
  
  .hero .icon-box:hover h3 a {
    color: var(--accent-color);
  }
  
  @media (max-width: 768px) {
    .hero h2 {
      font-size: 32px;
    }
  
    .hero p {
      font-size: 18px;
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .content h3 {
    font-size: 1.75rem;
    font-weight: 700;
  }
  
  .about .content .fst-italic {
    color: color-mix(in srgb, var(--default-color), var(--contrast-color) 50%);
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 10px 0 0 0;
    display: flex;
  }
  
  .about .content ul i {
    color: var(--accent-color);
    margin-right: 0.5rem;
    line-height: 1.2;
    font-size: 1.25rem;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
  .clients .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
    filter: grayscale(100);
  }
  
  .clients .swiper-slide img:hover {
    filter: none;
    opacity: 1;
  }
  
  .clients .swiper-wrapper {
    height: auto;
  }
  
  .clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
  .features .features-image {
    position: relative;
    min-height: 400px;
  }
  
  .features .features-image img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .features .features-item h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
  }
  
  .features .features-item i {
    font-size: 48px;
    color: var(--accent-color);
    margin-right: 20px;
    line-height: 0;
  }
  
  .features .features-item p {
    font-size: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0;
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services .service-item {
    text-align: center;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    padding: 80px 20px;
    height: 100%;
    transition: all ease-in-out 0.3s;
  }
  
  .services .service-item .icon {
    background: var(--accent-color);
    color: var(--contrast-color);
    margin: 0 auto;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  
  .services .service-item .icon i {
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item h3 {
    font-weight: 700;
    margin: 10px 0 15px 0;
    font-size: 22px;
    transition: 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .service-item:hover {
    box-shadow: 0px 0 25px 0 color-mix(in srgb, var(--default-color), transparent 92%);
    transform: translateY(-10px);
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    --background-color: #000000;
    --default-color: #ffffff;
    --contrast-color: #ffffff;
    padding: 80px 0;
    position: relative;
    clip-path: inset(0);
  }
  
  .call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--default-color);
  }
  
  .call-to-action p {
    color: var(--default-color);
  }
  
  .call-to-action .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid var(--contrast-color);
    color: var(--contrast-color);
  }
  
  .call-to-action .cta-btn:hover {
    background: var(--accent-color);
    color: var(--background-color);
    border: 2px solid var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
  .portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    font-family: var(--heading-font);
  }
  
  .portfolio .portfolio-filters li:hover,
  .portfolio .portfolio-filters li.filter-active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .portfolio .portfolio-filters li:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-filters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-filters li {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
  
  .portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: 0.3s;
    line-height: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: var(--accent-color);
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
  .stats .stats-item {
    padding: 10px;
  }
  
  .stats .stats-item i {
    font-size: 44px;
    color: var(--accent-color);
    line-height: 0;
    margin-right: 15px;
  }
  
  .stats .stats-item .purecounter {
    color: var(--heading-color);
    font-size: 40px;
    display: block;
    font-weight: 700;
    line-height: 40px;
  }
  
  .stats .stats-item p {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 15px 0 0 0;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Solutions Section
  --------------------------------------------------------------*/
  .solutions {
    --default-color: #ffffff;
    --background-color: #000000;
    --heading-color: #ffffff;
    padding: 80px 0;
    position: relative;
  }
  
  .solutions:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .solutions .solutions-bg {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .solutions .container {
    position: relative;
    z-index: 3;
  }
  
  .solutions .solutions-carousel,
  .solutions .solutions-slider {
    overflow: hidden;
  }
  
  .solutions .testimonial-item {
    text-align: center;
  }
  
  .solutions .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid color-mix(in srgb, var(--default-color), transparent 85%);
    margin: 0 auto;
  }
  
  .solutions .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .solutions .testimonial-item h4 {
    font-size: 14px;
    margin: 0 0 15px 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .solutions .testimonial-item .stars {
    margin-bottom: 15px;
  }
  
  .solutions .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .solutions .testimonial-item .quote-icon-left,
  .solutions .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 26px;
    line-height: 0;
  }
  
  .solutions .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .solutions .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .solutions .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
  }
  
  .solutions .swiper-wrapper {
    height: auto;
  }
  
  .solutions .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .solutions .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, var(--default-color), transparent 50%);
    opacity: 0.5;
  }
  
  .solutions .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--default-color);
    opacity: 1;
  }
  
  @media (min-width: 992px) {
    .solutions .testimonial-item p {
      width: 80%;
    }
  }
  
  /*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
  .team .team-member {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
    height: 100%;
  }
  
  .team .team-member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .team-member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
  }
  
  .team .team-member .social a {
    background: color-mix(in srgb, var(--background-color), transparent 25%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0 3px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    transition: ease-in-out 0.3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .team .team-member .social a:hover {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
  
  .team .team-member .social i {
    font-size: 18px;
    line-height: 0;
  }
  
  .team .team-member .member-info {
    padding: 25px 15px;
  }
  
  .team .team-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .team .team-member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .team .team-member:hover .social {
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-item+.info-item {
    margin-top: 40px;
  }
  
  .contact .info-item i {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .php-email-form {
    height: 100%;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color) 90%, white 20%);
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .service-box {
    padding: 20px;
    box-shadow: 0px 2px 20px color-mix(in srgb, var(--default-color), transparent 88%);
  }
  
  .service-details .service-box+.service-box {
    margin-top: 30px;
  }
  
  .service-details .service-box h4 {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .service-details .services-list a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    display: flex;
    align-items: center;
    padding: 12px 15px;
    margin-top: 15px;
    transition: 0.3s;
  }
  
  .service-details .services-list a:first-child {
    margin-top: 0;
  }
  
  .service-details .services-list a i {
    font-size: 16px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .services-list a.active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .service-details .services-list a.active i {
    color: var(--contrast-color);
  }
  
  .service-details .services-list a:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a {
    color: var(--default-color);
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: 0.3s;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .service-details .download-catalog a:first-child {
    border-top: 0;
    padding-top: 0;
  }
  
  .service-details .download-catalog a:last-child {
    padding-bottom: 0;
  }
  
  .service-details .download-catalog a i {
    font-size: 24px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a:hover {
    color: var(--accent-color);
  }
  
  .service-details .help-box {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    margin-top: 30px;
    padding: 30px 15px;
  }
  
  .service-details .help-box .help-icon {
    font-size: 48px;
  }
  
  .service-details .help-box h4,
  .service-details .help-box a {
    color: var(--contrast-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
  .starter-section {
    /* Add your styles here */
  }