.container {
  display: flex;
  flex-direction: column;
}

.text{
  text-align: left;
}


.text, .h2 {
  flex-grow: 1;
  padding: 20px;
  color: white; 
}

.image {
  padding: 20px;
}

img {
  width: 100%;
  height: auto;
}

@media (min-width: 600px) {
  .container {
    flex-direction: row;
    align-items: center;
  }

  .text, .h2 {
    margin-left: 10rem;
  }

  .image {
    flex-grow: 1;
    margin-right: 10rem;
  
  }

  img {
    width: 40rem;
  }
}
