/* src/Card.css */
.card {
  border: 2px solid rgb(0, 0, 0);
  display: inline-block;
  margin: 10px;
  transition: all 0.3s ease;
  text-align: center;
  max-width: 15rem;
  max-height: 11rem;
  overflow: hidden; 
  border-radius: 8px; 
  background-color: grey;
  position: relative;
}

.card-image {
  width: 100%;
  transition: transform 0.3s ease;
  display: block;
}

.card-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6); /* Arka plan rengi ve opaklığı */
  padding: 5px 0;
  border-radius: 0 0 8px 8px; 
  font-size: 0.9rem;
 
}
.content-text {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 90%; /* Kartın genişliğinin %90'ı kadar olacak şekilde ayarlanabilir */
  text-align: center;
  color: #78e478;
  font-size: 0.9rem;

  }
  .content-overlay {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Bu, .content-overlay elementinin tıklama ve olayları engellemesini sağlar */
  }

.content-overlay.show {
  opacity: 1;
}



.card.hovered {
  border-color: #78e478;
}

.card.hovered .card-image {
  transform: scale(1.1); 
  filter: brightness(10%);
}

.card.hovered .card-text {
  color: yellow;
  filter: brightness(10%) ;
  
  
}
