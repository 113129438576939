@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap);
@font-face {
  font-family: 'BestermindRegular';
  src: url(/static/media/BestermindRegular.e58a1922.ttf); 
  src: url(/static/media/BestermindRegular.e58a1922.ttf) format('embedded-opentype'), 
       url(/static/media/BestermindRegular.e58a1922.ttf)  format('truetype'), 
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
        -ms-user-select: none; 
            user-select: none; 

}


@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.navbar {
    height: 6rem;
    background-color: #21212146;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;

}

.navbar--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
    margin-top: 1rem;
}

.navbar--container > h1 {
    font-family: 'BestermindRegular';
    color: var(--primary-2);
    font-size: 2.5rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navLink--container {
    margin-top: 5rem;
}

.drawer > .MuiBackdrop-root {
    background: rgba(33, 33, 33, 0.15) !important;
    -webkit-backdrop-filter: blur(20px) !important;
            backdrop-filter: blur(20px) !important;
}




@media (max-width:1100px) {
    .navbar--container{
        padding: 0 2rem;
    }
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}
.nav-link-wrapper {
    margin-bottom: 1rem; 
}
@media (max-width: 768px) {
    .mobile-menu {
      display: none;
    }
    .mobile-menu2 {
        display: block;
      }
  }
  
  @media (min-width: 769px) {
    .mobile-menu {
      display: block;
      margin-right: 40rem;
      margin-left: 3rem;
    }
    .mobile-menu2 {
        display: none;
      }
  }
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}
.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    font-weight: 500;
    font-family: var(--primaryFont);
    font-size: 18px;
}

.footer p span {
    font-size: 24px;
}
.about {
    min-height: 100vh;
    position: relative;
}

.about .line-styling{
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 50px;
  width: 85%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about .line-styling .style-circle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.about .line-styling .style-line{
  width: 95%;
  height: 5px;
  border-radius: 10px;
}

.about-body {
    display: flex;
    justify-content: space-around;
    padding-top: 8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.8 1;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-description >h2 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-family: var(--primaryFont);
}

.about-description > p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #e0d9d9;
    font-family: var(--primaryFont);
}

.about-img {
    margin-top: 40px;
    pointer-events: none;
}

.about-img > img {
    width: 380px;
    flex: 0.5 1;
}

@media (min-width: 992px) and (max-width: 1380px) {

    .about-description > h2 {
      font-size: 3.3rem;
      margin-bottom: 30px;
    }
    .about-description > p {
      font-size: 1.15rem;
    }

    .about-img>img {
        width: 300px;
    } 
}

@media screen and (max-width: 992px){

  .about .line-styling{
    top: 20px;
  }

    .about-body {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }

    .about-description{
        flex: 0.6 1;
        box-sizing: border-box;
        /* padding: 5% 10%; */
        padding: 20px;

    }

    .about-description > h2 {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .about-description > p {
        font-size: 1.1rem;
    }

    .about-img > img {
        width: 250px;
        flex: 0.4 1;
    }

}

@media screen and (max-width: 800px){
  .about .line-styling .style-circle{
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .about-description > h2{
    align-self: center;
  }

}

@media screen and (max-width: 600px){

  .about .line-styling .style-circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .about .line-styling .style-line{
    width: 93%;
    height: 3px;
    border-radius: 10px;
  }

  .about-description > h2 {
    font-size: 2.5rem;
  }

    .about-description > p{
      font-size: 0.95rem;
    }

    .about-img > img {
        width: 200px;
    }
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 60vh;
    padding: 2.5rem 2rem 2rem 2rem;
}

.skillsHeader {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
}

.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3rem;
    padding: 0 2rem;
}

.skill--scroll {
    width: 100%;
    margin: 0 2rem;
}


.skill--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.skill--box:hover  {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}


.skill--box > img {
    height: 50px;
    max-width: 100px;
    pointer-events: none;
}


.skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}


.marquee {
    padding: 3rem 0;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .skills {
        padding: 2rem 1rem 1rem 1rem;
    }
    .skillsContainer {
        padding: 1.5rem;
        margin-top: 1.5rem;
    }
}


@media screen and (max-width: 992px) {
    .skills {
        padding: 1rem;
        min-height: 100%;
    }
    .skillsContainer {
        padding: 1rem;
        margin: 1rem 0;
    }
    .skillsHeader h2 {
        font-size: 3.2rem;
    }
    .skill--box {
        width: 150px;
        height: 150px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 45px;
    }


    .skill--box h3 {
        font-size: 20px;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 800px) {
    .skills {
        padding: 0.5rem;
    }
    .skillsContainer {
        padding: 0.5rem;
    }
    .skillsHeader h2 {
        font-size: 3rem;
    }

    .skill--scroll {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skillsHeader h2 {
        font-size: 2.5rem;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
/* src/Card.css */
.card {
  border: 2px solid rgb(0, 0, 0);
  display: inline-block;
  margin: 10px;
  transition: all 0.3s ease;
  text-align: center;
  max-width: 15rem;
  max-height: 11rem;
  overflow: hidden; 
  border-radius: 8px; 
  background-color: grey;
  position: relative;
}

.card-image {
  width: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  display: block;
}

.card-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6); /* Arka plan rengi ve opaklığı */
  padding: 5px 0;
  border-radius: 0 0 8px 8px; 
  font-size: 0.9rem;
 
}
.content-text {
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 90%; /* Kartın genişliğinin %90'ı kadar olacak şekilde ayarlanabilir */
  text-align: center;
  color: #78e478;
  font-size: 0.9rem;

  }
  .content-overlay {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Bu, .content-overlay elementinin tıklama ve olayları engellemesini sağlar */
  }

.content-overlay.show {
  opacity: 1;
}



.card.hovered {
  border-color: #78e478;
}

.card.hovered .card-image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); 
  -webkit-filter: brightness(10%); 
          filter: brightness(10%);
}

.card.hovered .card-text {
  color: yellow;
  -webkit-filter: brightness(10%) ;
          filter: brightness(10%) ;
  
  
}

.custom-card {
  border: 2px solid rgb(0, 0, 0);
  display: inline-block;
  margin: 10px;
  transition: all 0.3s ease;
  text-align: center;
  max-width: 30rem;
  max-height: 17rem;
  overflow: hidden; /* Kartın içeriğinin taşmasını önler */
  border-radius: 8px; /* Kenarları yuvarlatmak için */
  background-color: grey;
  position: relative;
}

.custom-card-image {
  width: 100%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  display: block;
}

.custom-card-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6); /* Arka plan rengi ve opaklığı */
  padding: 5px 0;
  border-radius: 0 0 8px 8px; 
  font-size: 1.2rem;
 
}
.content-text {
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 90%; /* Kartın genişliğinin %90'ı kadar olacak şekilde ayarlanabilir */
  text-align: center;
  color: #78e478;
  font-size: 0.9rem;

  }
  .content-overlay {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Bu, .content-overlay elementinin tıklama ve olayları engellemesini sağlar */
  }

.content-overlay.show {
  opacity: 1;
}



.custom-card.hovered {
  border-color: #78e478;
}

.custom-card.hovered .custom-card-image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); 
  -webkit-filter: brightness(10%); 
          filter: brightness(10%);
}

.custom-card.hovered .custom-card-text {
  color: yellow;
  -webkit-filter: brightness(10%) ;
          filter: brightness(10%) ;
}

/**
* Template Name: Gp
* Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
* Updated: Jun 02 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
  
:root {
    --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway", sans-serif;
    --nav-font: "Poppins", sans-serif;
  }
  
  /* Global Colors */
  :root {
    /* Background Color - This color is applied to the background of the entire website as well as individual sections. */
    --background-color: #ffffff;
  
    /* Default Color - This is the default color used for the majority of the text content. */
    --default-color: #444444;
  
    /* Heading Color - This color is used for titles, headings and secondary elements. */
    --heading-color: #151515;
  
    /* Accent Color - This is the main accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out. */
    --accent-color: #ffc451;
  
    /* Contrast Color - This is a color used for text when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors. */
    --contrast-color: #312f2f;
  }
  
  /* Nav Menu Colors */
  :root {
    /* Nav Color - This is the default color of the main navmenu links. */
    --nav-color: rgba(255, 255, 255, 0.905);
  
    /* Nav Hover Color - This color is applied to main navmenu links when they are hovered over. */
    --nav-hover-color: #ffc451;
  
    /* Nav Dropdown Background Color - This color is used as the background for dropdown boxes that appear when hovering over primary navigation items. */
    --nav-dropdown-background-color: #ffffff;
  
    /* Nav Dropdown Color - This color is used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-color: #212529;
  
    /* Nav Dropdown Hover Color - Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
    --nav-dropdown-hover-color: #ffc451;
  }
  
  /* Template Custom Colors */
  :root {
    --footer-background-color: #000000;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    color: #444444;
    color: var(--default-color);
    background-color: #ffffff;
    background-color: var(--background-color);
    font-family: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--default-font);
  }
  
  a {
    color: #ffc451;
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, #ffc451, transparent 25%);
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #151515;
    color: var(--heading-color);
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    --background-color: rgba(0, 0, 0, 0.8);
    --heading-color: #ffffff;
    --contrast-color: #ffffff;
    color: #444444;
    color: var(--default-color);
    background-color: #ffffff;
    background-color: var(--background-color);
    padding: 10px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 36px;
    margin-right: 8px;
  }
  
  .header .logo h1 {
    font-size: 32px;
    margin: 0;
    font-weight: 700;
    color: #151515;
    color: var(--heading-color);
  }
  
  .header .logo span {
    color: #ffc451;
    color: var(--accent-color);
    font-size: 32px;
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: #312f2f;
    color: var(--contrast-color);
    font-size: 14px;
    padding: 8px 30px;
    margin: 0 0 0 30px;
    border-radius: 4px;
    transition: 0.3s;
    border: 2px solid #ffc451;
    border: 2px solid var(--accent-color);
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: #444444;
    color: var(--default-color);
    background: #ffc451;
    background: var(--accent-color);
  }
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 15px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px color-mix(in srgb, #444444, transparent 85%);
    box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  /* Index Page Header
  ------------------------------*/
  .index-page .header {
    --background-color: rgba(0, 0, 0, 0);
  }
  
  /* Index Page Header on Scroll
  ------------------------------*/
  .index-page.scrolled .header {
    --background-color: rgba(0, 0, 0, 0.8);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Navmenu - Desktop */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: rgba(255, 255, 255, 0.905);
      color: var(--nav-color);
      padding: 18px 15px;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:last-child a {
      padding-right: 0;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: #ffc451;
      color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: #ffffff;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 0;
      z-index: 99;
      box-shadow: 0px 0px 30px color-mix(in srgb, #444444, transparent 85%);
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: #212529;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      background-color: #ffc451;
      background-color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Navmenu - Mobile */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: rgba(255, 255, 255, 0.905);
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: #ffffff;
      background-color: var(--nav-dropdown-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px color-mix(in srgb, #444444, transparent 90%);
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 90%);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: #212529;
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: "Poppins", sans-serif;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, #ffc451, white 90%);
      background-color: color-mix(in srgb, var(--accent-color), white 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: color-mix(in srgb, #ffc451, white 90%);
      background-color: color-mix(in srgb, var(--accent-color), white 90%);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      background-color: #ffc451;
      background-color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: #ffffff;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, #444444, transparent 90%);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    --background-color: var(--footer-background-color);
    --default-color: #ffffff;
    --heading-color: #ffffff;
    color: #444444;
    color: var(--default-color);
    background-color: #ffffff;
    background-color: var(--background-color);
    font-size: 14px;
    position: relative;
  }
  
  .footer .footer-top {
    padding: 50px 0;
    background-color: color-mix(in srgb, #000000 90%, white 10%);
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 10%);
  }
  
  .footer .footer-about .logo {
    line-height: 1;
    margin-bottom: 10px;
  }
  
  .footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .footer .footer-about .logo span {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
    color: #151515;
    color: var(--heading-color);
  }
  
  .footer .footer-about p {
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
  }
  
  .footer .social-links a {
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    background-color: color-mix(in srgb, #444444 5%, white 10%);
    background-color: color-mix(in srgb, var(--default-color) 5%, white 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: #312f2f;
    color: var(--contrast-color);
    background-color: #ffc451;
    background-color: var(--accent-color);
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul i {
    margin-right: 4px;
    font-size: 12px;
    line-height: 0;
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .footer .footer-contact p {
    margin-bottom: 5px;
  }
  
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
    padding: 6px 8px;
    position: relative;
    border-radius: 4px;
    border: 1px solid color-mix(in srgb, #444444, transparent 80%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
    display: flex;
    background-color: color-mix(in srgb, #000000 90%, white 15%);
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
    transition: 0.3s;
  }
  
  .footer .footer-newsletter .newsletter-form:focus-within {
    border-color: #ffc451;
    border-color: var(--accent-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email] {
    border: 0;
    padding: 4px;
    width: 100%;
    background-color: color-mix(in srgb, #000000 90%, white 15%);
    background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
    color: #444444;
    color: var(--default-color);
  }
  
  .footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: -7px -8px -7px 0;
    background: #ffc451;
    background: var(--accent-color);
    color: #312f2f;
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
  }
  
  .footer .footer-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, #ffc451, transparent 20%);
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .footer .footer-newsletter .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .footer-newsletter .loading {
    display: none;
    background: #ffffff;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-top: 10px;
  }
  
  .footer .footer-newsletter .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #ffc451;
    border: 3px solid var(--accent-color);
    border-top-color: #ffffff;
    border-top-color: var(--background-color);
    -webkit-animation: subscription-loading 1s linear infinite;
            animation: subscription-loading 1s linear infinite;
  }
  
  @-webkit-keyframes subscription-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes subscription-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .footer .copyright {
    padding: 30px 0;
  }
  
  .footer .copyright p {
    margin-bottom: 0;
  }
  
  .footer .credits {
    margin-top: 5px;
    font-size: 13px;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: #000;
    transition: all 0.6s ease-out;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: #ffc451 transparent #ffc451 transparent;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1.5s linear infinite;
            animation: animate-preloader 1.5s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: #ffc451;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: #312f2f;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, #ffc451, transparent 20%);
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: #312f2f;
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    color: #444444;
    color: var(--default-color);
    background-color: #ffffff;
    background-color: var(--background-color);
    position: relative;
  }
  
  .page-title .heading {
    padding: 80px 0;
    border-top: 1px solid color-mix(in srgb, #444444, transparent 90%);
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .page-title .heading h1 {
    font-size: 38px;
    font-weight: 700;
  }
  
  .page-title nav {
    background-color: color-mix(in srgb, #444444, transparent 95%);
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 20px 0;
  }
  
  .page-title nav ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .page-title nav ol li+li {
    padding-left: 10px;
  }
  
  .page-title nav ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, #444444, transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: #444444;
    color: var(--default-color);
    background-color: #ffffff;
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 80px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 58px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: color-mix(in srgb, #444444, transparent 50%);
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    position: relative;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #ffc451;
    background: var(--accent-color);
    margin: 4px 10px;
  }
  
  .section-title p {
    color: #151515;
    color: var(--heading-color);
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    --default-color: #ffffff;
    --background-color: #000000;
    --heading-color: #ffffff;
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 120px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .hero:before {
    content: "";
    background: color-mix(in srgb, #ffffff, transparent 30%);
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .hero .container {
    position: relative;
    z-index: 3;
  }
  
  .hero h2 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    font-family: var(--nav-font);
  }
  
  .hero h2 span {
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .hero p {
    margin: 10px 0 0 0;
    font-size: 24px;
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .hero .icon-box {
    padding: 30px 20px;
    transition: ease-in-out 0.3s;
    border: 1px solid color-mix(in srgb, #444444, transparent 70%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .hero .icon-box h3 {
    font-weight: 700;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    font-size: 20px;
    line-height: 26px;
  }
  
  .hero .icon-box h3 a {
    color: color-mix(in srgb, #444444, transparent 20%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: ease-in-out 0.3s;
  }
  
  .hero .icon-box:hover {
    border-color: #ffc451;
    border-color: var(--accent-color);
  }
  
  .hero .icon-box:hover h3 a {
    color: #ffc451;
    color: var(--accent-color);
  }
  
  @media (max-width: 768px) {
    .hero h2 {
      font-size: 32px;
    }
  
    .hero p {
      font-size: 18px;
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .content h3 {
    font-size: 1.75rem;
    font-weight: 700;
  }
  
  .about .content .fst-italic {
    color: color-mix(in srgb, #444444, #312f2f 50%);
    color: color-mix(in srgb, var(--default-color), var(--contrast-color) 50%);
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding: 10px 0 0 0;
    display: flex;
  }
  
  .about .content ul i {
    color: #ffc451;
    color: var(--accent-color);
    margin-right: 0.5rem;
    line-height: 1.2;
    font-size: 1.25rem;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
  .clients .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
  }
  
  .clients .swiper-slide img:hover {
    -webkit-filter: none;
            filter: none;
    opacity: 1;
  }
  
  .clients .swiper-wrapper {
    height: auto;
  }
  
  .clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: color-mix(in srgb, #444444, transparent 80%);
    background-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
  .features .features-image {
    position: relative;
    min-height: 400px;
  }
  
  .features .features-image img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .features .features-item h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
  }
  
  .features .features-item i {
    font-size: 48px;
    color: #ffc451;
    color: var(--accent-color);
    margin-right: 20px;
    line-height: 0;
  }
  
  .features .features-item p {
    font-size: 15px;
    color: color-mix(in srgb, #444444, transparent 20%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0;
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services .service-item {
    text-align: center;
    border: 1px solid color-mix(in srgb, #444444, transparent 85%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    padding: 80px 20px;
    height: 100%;
    transition: all ease-in-out 0.3s;
  }
  
  .services .service-item .icon {
    background: #ffc451;
    background: var(--accent-color);
    color: #312f2f;
    color: var(--contrast-color);
    margin: 0 auto;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
  }
  
  .services .service-item .icon i {
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item h3 {
    font-weight: 700;
    margin: 10px 0 15px 0;
    font-size: 22px;
    transition: 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .service-item:hover {
    box-shadow: 0px 0 25px 0 color-mix(in srgb, #444444, transparent 92%);
    box-shadow: 0px 0 25px 0 color-mix(in srgb, var(--default-color), transparent 92%);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    --background-color: #000000;
    --default-color: #ffffff;
    --contrast-color: #ffffff;
    padding: 80px 0;
    position: relative;
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
  }
  
  .call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action:before {
    content: "";
    background: color-mix(in srgb, #ffffff, transparent 50%);
    background: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    font-size: 28px;
    font-weight: 700;
    color: #444444;
    color: var(--default-color);
  }
  
  .call-to-action p {
    color: #444444;
    color: var(--default-color);
  }
  
  .call-to-action .cta-btn {
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #312f2f;
    border: 2px solid var(--contrast-color);
    color: #312f2f;
    color: var(--contrast-color);
  }
  
  .call-to-action .cta-btn:hover {
    background: #ffc451;
    background: var(--accent-color);
    color: #ffffff;
    color: var(--background-color);
    border: 2px solid #ffc451;
    border: 2px solid var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
  .portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
  }
  
  .portfolio .portfolio-filters li:hover,
  .portfolio .portfolio-filters li.filter-active {
    color: #312f2f;
    color: var(--contrast-color);
    background-color: #ffc451;
    background-color: var(--accent-color);
  }
  
  .portfolio .portfolio-filters li:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-filters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-filters li {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
  
  .portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: color-mix(in srgb, #ffffff, transparent 10%);
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: 0.3s;
    line-height: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
  .stats .stats-item {
    padding: 10px;
  }
  
  .stats .stats-item i {
    font-size: 44px;
    color: #ffc451;
    color: var(--accent-color);
    line-height: 0;
    margin-right: 15px;
  }
  
  .stats .stats-item .purecounter {
    color: #151515;
    color: var(--heading-color);
    font-size: 40px;
    display: block;
    font-weight: 700;
    line-height: 40px;
  }
  
  .stats .stats-item p {
    color: color-mix(in srgb, #444444, transparent 40%);
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 15px 0 0 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-family: var(--heading-font);
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Solutions Section
  --------------------------------------------------------------*/
  .solutions {
    --default-color: #ffffff;
    --background-color: #000000;
    --heading-color: #ffffff;
    padding: 80px 0;
    position: relative;
  }
  
  .solutions:before {
    content: "";
    background: color-mix(in srgb, #ffffff, transparent 30%);
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .solutions .solutions-bg {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .solutions .container {
    position: relative;
    z-index: 3;
  }
  
  .solutions .solutions-carousel,
  .solutions .solutions-slider {
    overflow: hidden;
  }
  
  .solutions .testimonial-item {
    text-align: center;
  }
  
  .solutions .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid color-mix(in srgb, #444444, transparent 85%);
    border: 6px solid color-mix(in srgb, var(--default-color), transparent 85%);
    margin: 0 auto;
  }
  
  .solutions .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .solutions .testimonial-item h4 {
    font-size: 14px;
    margin: 0 0 15px 0;
    color: color-mix(in srgb, #444444, transparent 40%);
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .solutions .testimonial-item .stars {
    margin-bottom: 15px;
  }
  
  .solutions .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .solutions .testimonial-item .quote-icon-left,
  .solutions .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, #444444, transparent 40%);
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 26px;
    line-height: 0;
  }
  
  .solutions .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .solutions .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    -webkit-transform: scale(-1, -1);
            transform: scale(-1, -1);
  }
  
  .solutions .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
  }
  
  .solutions .swiper-wrapper {
    height: auto;
  }
  
  .solutions .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .solutions .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, #444444, transparent 50%);
    background-color: color-mix(in srgb, var(--default-color), transparent 50%);
    opacity: 0.5;
  }
  
  .solutions .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #444444;
    background-color: var(--default-color);
    opacity: 1;
  }
  
  @media (min-width: 992px) {
    .solutions .testimonial-item p {
      width: 80%;
    }
  }
  
  /*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
  .team .team-member {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 2px 15px color-mix(in srgb, #444444, transparent 90%);
    box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
    height: 100%;
  }
  
  .team .team-member .member-img {
    position: relative;
    overflow: hidden;
  }
  
  .team .team-member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    text-align: center;
  }
  
  .team .team-member .social a {
    background: color-mix(in srgb, #ffffff, transparent 25%);
    background: color-mix(in srgb, var(--background-color), transparent 25%);
    color: color-mix(in srgb, #444444, transparent 20%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0 3px;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    transition: ease-in-out 0.3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .team .team-member .social a:hover {
    color: #312f2f;
    color: var(--contrast-color);
    background: #ffc451;
    background: var(--accent-color);
  }
  
  .team .team-member .social i {
    font-size: 18px;
    line-height: 0;
  }
  
  .team .team-member .member-info {
    padding: 25px 15px;
  }
  
  .team .team-member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .team .team-member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: color-mix(in srgb, #444444, transparent 40%);
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .team .team-member:hover .social {
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-item+.info-item {
    margin-top: 40px;
  }
  
  .contact .info-item i {
    color: #312f2f;
    color: var(--contrast-color);
    background: #ffc451;
    background: var(--accent-color);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .php-email-form {
    height: 100%;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #ffffff;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #ffc451;
    border: 3px solid var(--accent-color);
    border-top-color: #ffffff;
    border-top-color: var(--background-color);
    -webkit-animation: animate-loading 1s linear infinite;
            animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: #444444;
    color: var(--default-color);
    background-color: color-mix(in srgb, #ffffff, transparent 50%);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, #444444, transparent 80%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: #ffc451;
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::-webkit-input-placeholder, .contact .php-email-form input[type=email]::-webkit-input-placeholder, .contact .php-email-form textarea::-webkit-input-placeholder {
    color: color-mix(in srgb, #444444, transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form input[type=text]:-ms-input-placeholder, .contact .php-email-form input[type=email]:-ms-input-placeholder, .contact .php-email-form textarea:-ms-input-placeholder {
    color: color-mix(in srgb, #444444, transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, #444444, transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: #312f2f;
    color: var(--contrast-color);
    background: #ffc451;
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, #ffc451 90%, white 20%);
    background: color-mix(in srgb, var(--accent-color) 90%, white 20%);
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, #444444, transparent 85%);
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffc451;
    background-color: var(--accent-color);
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px color-mix(in srgb, #444444, transparent 90%);
    box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid color-mix(in srgb, #444444, transparent 85%);
    border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
    color: color-mix(in srgb, #444444, transparent 30%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .service-box {
    padding: 20px;
    box-shadow: 0px 2px 20px color-mix(in srgb, #444444, transparent 88%);
    box-shadow: 0px 2px 20px color-mix(in srgb, var(--default-color), transparent 88%);
  }
  
  .service-details .service-box+.service-box {
    margin-top: 30px;
  }
  
  .service-details .service-box h4 {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid color-mix(in srgb, #444444, transparent 92%);
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .service-details .services-list a {
    color: color-mix(in srgb, #444444, transparent 20%);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    background-color: color-mix(in srgb, #444444, transparent 96%);
    background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    display: flex;
    align-items: center;
    padding: 12px 15px;
    margin-top: 15px;
    transition: 0.3s;
  }
  
  .service-details .services-list a:first-child {
    margin-top: 0;
  }
  
  .service-details .services-list a i {
    font-size: 16px;
    margin-right: 8px;
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .service-details .services-list a.active {
    color: #312f2f;
    color: var(--contrast-color);
    background-color: #ffc451;
    background-color: var(--accent-color);
  }
  
  .service-details .services-list a.active i {
    color: #312f2f;
    color: var(--contrast-color);
  }
  
  .service-details .services-list a:hover {
    background-color: color-mix(in srgb, #ffc451, transparent 95%);
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a {
    color: #444444;
    color: var(--default-color);
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: 0.3s;
    border-top: 1px solid color-mix(in srgb, #444444, transparent 90%);
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .service-details .download-catalog a:first-child {
    border-top: 0;
    padding-top: 0;
  }
  
  .service-details .download-catalog a:last-child {
    padding-bottom: 0;
  }
  
  .service-details .download-catalog a i {
    font-size: 24px;
    margin-right: 8px;
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a:hover {
    color: #ffc451;
    color: var(--accent-color);
  }
  
  .service-details .help-box {
    background-color: #ffc451;
    background-color: var(--accent-color);
    color: #312f2f;
    color: var(--contrast-color);
    margin-top: 30px;
    padding: 30px 15px;
  }
  
  .service-details .help-box .help-icon {
    font-size: 48px;
  }
  
  .service-details .help-box h4,
  .service-details .help-box a {
    color: #312f2f;
    color: var(--contrast-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: #ffc451;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
  .starter-section {
    /* Add your styles here */
  }
.container {
  display: flex;
  flex-direction: column;
}

.text{
  text-align: left;
}


.text, .h2 {
  flex-grow: 1;
  padding: 20px;
  color: white; 
}

.image {
  padding: 20px;
}

img {
  width: 100%;
  height: auto;
}

@media (min-width: 600px) {
  .container {
    flex-direction: row;
    align-items: center;
  }

  .text, .h2 {
    margin-left: 10rem;
  }

  .image {
    flex-grow: 1;
    margin-right: 10rem;
  
  }

  img {
    width: 40rem;
  }
}

.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 2rem;
    overflow-x: hidden;
}


.blog--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.blog--header h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-family: var(--primaryFont);
}

.blog--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog--bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-gap: 4.5rem;
    gap: 4.5rem;
}

.blog--viewAll {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.blog--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}


@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .blog--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .blog--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .blog--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .blog--viewAll {
        position: relative;
        margin-top: 2.5rem;
    }
    .blog--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleBlog {
    height: 420px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    border-radius: 5px;
}

.singleBlog--image {
    height: 50%;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.singleBlog--image > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: -webkit-transform 1s, -webkit-filter 2s ease-in-out;
    transition: transform 1s, filter 2s ease-in-out;
    transition: transform 1s, filter 2s ease-in-out, -webkit-transform 1s, -webkit-filter 2s ease-in-out;
    -webkit-filter: blur(1px);
            filter: blur(1px);
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.singleBlog:hover > .singleBlog--image > img {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.singleBlog--body {
    padding: 1rem;
}

.singleBlog--body p {
    font-family: var(--primaryFont);
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0.65rem;
}

.singleBlog--body h3 {
    font-family: var(--primaryFont);
    margin-bottom: 0.55rem;
    font-size: 19px;
    line-height: 115%;
}

.singleBlog--body h6 {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 15px;
    line-height: 139%;
    font-weight: 500;
    opacity: 0.9;
}



@media (min-width: 992px) and (max-width: 1380px) {
    .singleBlog {
        height: 430px;
    }
}


@media screen and (max-width: 992px) {
    .singleBlog {
        height: 450px;
        background-color: rgb(171, 240, 240);
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
    }
}

@media screen and (max-width: 800px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 700px) {
    .singleBlog {
        height: 450px;
        margin: 1rem auto;
    }
}

@media screen and (max-width: 600px) {
    .singleBlog {
        height: 450px;
        width: 300px;
        margin: 1rem 0;
    }
    .singleBlog--image > img {
        -webkit-filter: blur(0);
                filter: blur(0);
    }
}

@media screen and (max-width: 320px) {
    .singleBlog {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}


@media only screen and (device-width: 768px) {

}
.education {
    min-height: 100vh;
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7%;
}

.education-description > h1{
    font-size: 3rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.education-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.educard-img > img {
    width: 40px;
}

.education-details{
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.education-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-top: 1rem;
    pointer-events: none;
}

.education-image > img {
    width: 100%;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }
    .education-image {
        display: none;
    }
    .education-description {
        flex: 1 1;
        margin: auto;
    }    
    .education-description > h1{
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    }
    .education-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .educard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .educard-img > img {
        width: 30px;
    }
    .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .education-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1rem;
    background-color: #212121;
    
}

.image-gallery img {
    width: 100%;
    max-width: 18%;
    margin-bottom: 10px;
    border: #78e478;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.image-gallery img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

@media (max-width: 768px) {
    .image-gallery img {
        max-width: 48%;
    }
}

@media (max-width: 480px) {
    .image-gallery img {
        max-width: 98%;
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #212121;
    
}

h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 36px;
    color: rgb(146, 146, 146)}
 

    @media only screen and (max-width: 768px) {
        .type-writer-container {
          display: none !important;
        }
      }
.experience{
    min-height: 100vh;
}

.experience-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 2rem;
}

.experience-description > h1 {
    font-size: 3rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.expcard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

.experience-details {
    margin-left: 0.6rem;
}

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}






@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .experience {
        min-height: 100%;
    }
    .experience-image {
        display: none;
    }
    .experience-description {
        flex: 1 1;
        margin: auto;
    }    
    .experience-description > h1{
        font-size: 3.2rem;
    }
    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .experience-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .experience-description {
        padding: 1rem;
    }
    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .expcard-img > img {
        width: 30px;
    }
    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .experience-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Main contact form container */
  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    background-color: #1c1c1c;
    padding: 20px;
    color: #fff;
  }
  
  .form-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    margin: 20px;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: 20px;
  }
  
  .info-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    
  }
  
  .info-card h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #999;
  }
  
  .info-card p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .info-card a {
    color: #43f560;
    text-decoration: none;
  }
  
  .info-card a:hover {
    text-decoration: underline;
  }
  
  /* Form elements */
  form {
    display: flex;
    flex-direction: column;
  }
  
  .user {
    background: #2e2e2e;
    border: none;
    padding: 12px;
    margin: 10px 0;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
  }
  
  .user::-webkit-input-placeholder {
    color: #999;
  }
  
  .user:-ms-input-placeholder {
    color: #999;
  }
  
  .user::placeholder {
    color: #999;
  }
  
  textarea.user {
    resize: vertical;
    height: 150px;
  }
  
  .button {
    background: #2da833;
    color: #fff;
    border: none;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .button:hover {
    background: #41e932;
  }
  
  .button-disabled {
    background: #444;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .contact-form {
      flex-direction: column;
      align-items: center;
      margin-left: -2rem;
    }
  
    .form-container, .contact-info {
      max-width: 100%;
    }
  
    .user {
      padding: 10px;
      font-size: 14px;
    }
  
    .button {
      padding: 10px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {

    .contact-form {
      flex-direction: column;
      align-items: center;
      margin-left: -2rem;
    }

    .user {
      padding: 8px;
      font-size: 12px;
    }
  
    .button {
      padding: 8px;
      font-size: 12px;
    }
  }
  
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 2rem;
}


.projects--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.projects--header h1 {
    margin-bottom: 40px;
    font-size: 3rem;
    font-family: var(--primaryFont);
}

.projects--body {
    width: 100%;
    color:black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects--bodyContainer {
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    width: 100%;
}

.projects--viewAll {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
}

.projects--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .projects--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projects--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .projects--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .projects--viewAll {
        position: relative;
        margin-top: 4rem;
    }
    .projects--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 304px;
    height: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 170px;
    height: auto;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
    -webkit-transform: translateX(-140px) scale(1.1) !important;
            transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
    position: absolute;
    width: 100%;
    height: 210px;
    left: 0;
    top: 0;
    -webkit-transform: translate(-110%,40%);
            transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: -webkit-transform 0.9s;
    transition: transform 0.9s;
    transition: transform 0.9s, -webkit-transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    -webkit-transform: translate(-2%,40%);
            transform: translate(-2%,40%);
}
  
.singleProject .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
    padding: 0.825rem;
}
  
.singleProject:hover .project--lang{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.project--lang > span {
    font-family: var(--primaryFont);
    font-weight: 500;
    word-break: break-word; 
    line-height: 100%;
}
.services {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3.7rem;
}

.services-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.services-header > h1 {
    margin-bottom: 20px;
    font-size: 3rem;
    font-family: var(--primaryFont);
}

.services-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.services-body > p {
    font-family: var(--primaryFont);
    font-weight: 400;
    font-size: 1.5rem;
    width: 60%;
    text-align: center;
    margin-bottom: 6rem;
}

.services-bodycontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    width: 100%;
    flex-wrap: wrap;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .services {
        padding: 2.7rem;
    }
    .services-body > p {
        width: 70%;
    }
}


@media screen and (max-width: 992px) {
    .services {
        padding: 1.7rem;
    }

    .services-body > p {
        font-size: 1.25rem;
        width: 85%;
        text-align: center;
        margin-bottom: 4rem;
    }

    .services-bodycontainer {
        grid-gap: 2.5rem;
        gap: 2.5rem;
    }
}

@media screen and (max-width: 800px) {
    .services {
        padding: 1rem;
    }
    .services-header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .services-body > p {
        font-size: 1.05rem;
        width: 95%;
        text-align: center;
        margin-bottom: 3rem;
    }

    .services-bodycontainer {
        grid-gap: 2rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .services {
        padding: 0.5rem;
    }
    .services-header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .services-body p{
        width: 80%;
    }
    .services-bodycontainer {
        grid-gap: 1rem;
        gap: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.single-service {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 180px;
    height: 180px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 1.4rem;
    /* overflow: hidden; */
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.single-service:hover {
    -webkit-transform: scale(1.15) ;
            transform: scale(1.15) ;
}

.service-content {
    position: relative;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.service-icon {
    position: absolute;
    top: 15%;
    /* transform: translateY(-50%); */
}

.service-icon > svg {
    font-size: 4rem;
    /* color: #000000; */
}

.single-service:hover svg{
    -webkit-animation: magic 0.7s ease-in-out both;
            animation: magic 0.7s ease-in-out both;
}

@-webkit-keyframes magic {
    0%, 100%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    25%{
        -webkit-transform: translate(0,-20px);
                transform: translate(0,-20px);
    }
    50%{
        -webkit-transform: translate(0,0px);
                transform: translate(0,0px);
    }
    75%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }

    /* slide-up-fade

    0%, 100%{
        transform: translate(0,0);
        opacity: 1;
    }
    33%{
        transform: translate(0,-50px);
        opacity: 0;
    }
    67%{
        transform: translate(0,50px);
        opacity: 0;
    } */
    
}

@keyframes magic {
    0%, 100%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    25%{
        -webkit-transform: translate(0,-20px);
                transform: translate(0,-20px);
    }
    50%{
        -webkit-transform: translate(0,0px);
                transform: translate(0,0px);
    }
    75%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }

    /* slide-up-fade

    0%, 100%{
        transform: translate(0,0);
        opacity: 1;
    }
    33%{
        transform: translate(0,-50px);
        opacity: 0;
    }
    67%{
        transform: translate(0,50px);
        opacity: 0;
    } */
    
}

.service-content > h4 {
    position: absolute;
    top: 60%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 110%;
    color: #000000;
}

@media screen and (max-width: 800px) {
    .single-service {
        width: 160px;
        height: 160px;
        padding: 1.2rem 1.2rem;
    }
    .service-icon > svg {
        font-size: 2.5rem;
    }
    
    .service-content > h4 {
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .single-service {
        width: 150px;
        height: 150px;
        padding: 1.2rem 1.2rem;
    }
    .service-icon > svg {
        font-size: 2.2rem;
    }
    
    .service-content > h4 {
        font-size: 13px;
        top: 50%;
    }
}
.achievement {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
}

.achievement-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.4 1;
    width: 90%;
}

.achievement-body h1 {
    font-family: var(--primaryFont);
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.achievement-body h4 {
    font-size: 1.75rem;
    font-family: 'Caveat', cursive;
    font-weight: 400;
}

.achievement-cards {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 5%;
    width: 100%;
    flex: 0.6 1;
}

.achievement-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 170px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    transition: background-color 200ms ease-in-out;
    width: 100%;
    position: relative;
}

.achievecard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 65%;
   
}

.achievecard-details2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    position: absolute;
    bottom: 7px;
    left: 25px;
}

.achievecard-details2 h5 {
   width: 50%;
}

.achievecard-field {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.achievecard-field svg {
    margin-right: 6px;
}

.achievecard-details1 h2 {
    font-family: var(--primaryFont);
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 110%;
    margin-bottom: 6px;
}

.achievecard-details1 p {
    font-size: 1.10rem;
    font-family: Roboto;
    font-weight: 400;
}

.achievecard-imgcontainer {
    width: 23%;
}

.achievecard-imgcontainer > img {
    width: 150px;
    height: 130px;
    border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .achievement-card {
        justify-content: flex-start;
    }

    .achievement-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        flex: 0.3 1;
        width: 90%;
    }
    .achievement-body h1 {
        font-family: var(--primaryFont);
        font-size: 3.1rem;
    }
    
    .achievement-cards {
        padding: 1rem;
        margin-left: 1%;
        width: 100%;
        flex: 0.7 1;
    }
    .achievecard-imgcontainer {
       margin-left: 1rem;
    }

}

@media screen and (max-width: 992px) {
    .achievement{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2rem;
    }


    .achievement-body {
        width: 100%;
    }

    .achievement-body h1 {
        font-size: 2.5rem;
    }

    .achievement-cards {
        margin-left: 0%;
        padding: 1rem 0;
        width: 80%;
        margin-top: 1rem;
    }

    .achievement-card {
        margin-left: 0px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .achievecard-content {
        width: 70%;
    }

    .achievecard-details1 {
        margin-bottom: 20px;
    }

    .achievecard-details1 h2 {
        font-size: 1.15rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }

    .achievecard-details2 {
        width: 85%;
        position: absolute;
        bottom: 7px;
        left: 25px;
    }

    .achievecard-imgcontainer {
        width: 30%;
        display: flex;
       justify-content: center;
    }

    .achievecard-imgcontainer > img {
        width: 80px;
        height: 70px;
    }
    
}

@media screen and (max-width: 600px) {
    .achievement{
        padding: 1rem;
    }
    .achievement-cards {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    .achievement-body h4 {
        font-size: 1.5rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }
    .achievecard-imgcontainer > img {
        width: 70px;
        height: 60px;
    }

}


.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
.blogPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}


.blogPage--header {
    height: 35vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.blogPage--header h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.blogPage--container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.blog--search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog--input {
    width: 30%;
    height: 2.75rem;
    outline: none;
    border: none;
    border-radius: 20px;
    padding: 0.25rem 1rem;
}

.blog--input::-webkit-input-placeholder {
    color: white;
}

.blog--input:-ms-input-placeholder {
    color: white;
}

.blog--input::placeholder {
    color: white;
}

.blogs--container {
    margin-top: 5rem;
    width: 100%;
}


.blog-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem;   
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .blogPage--header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .blog-grid {
        grid-gap: 3rem;
        gap: 3rem;   
    }
    .blog--input {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 702px) {
    .blog-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .blogPage--header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .blogPage--header {
        height: 20vh;
    }
    .blogPage--header h1 {
        font-size: 2.5rem;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.projectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}

.projectPage-header {
    height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.projectPage-header > h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.projectPage-container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.projectPage-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    margin-top: 5rem;
    width: 100%;
}

.project-grid {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem; 
}

@media (min-width: 992px) and (max-width: 1380px) {

}

@media screen and (max-width: 992px) {
    .projectPage-header {
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .project-grid {
        grid-gap: 3rem;
        gap: 3rem;
    }
}

@media screen and (max-width: 702px) {
    .project-grid {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .projectPage-header h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .projectPage-header {
        height: 20vh;
    }
    .projectPage-header h1 {
        font-size: 2.5rem;
    }
}
:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
}


