/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Main contact form container */
  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    background-color: #1c1c1c;
    padding: 20px;
    color: #fff;
  }
  
  .form-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    margin: 20px;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: 20px;
  }
  
  .info-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    
  }
  
  .info-card h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #999;
  }
  
  .info-card p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .info-card a {
    color: #43f560;
    text-decoration: none;
  }
  
  .info-card a:hover {
    text-decoration: underline;
  }
  
  /* Form elements */
  form {
    display: flex;
    flex-direction: column;
  }
  
  .user {
    background: #2e2e2e;
    border: none;
    padding: 12px;
    margin: 10px 0;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
  }
  
  .user::placeholder {
    color: #999;
  }
  
  textarea.user {
    resize: vertical;
    height: 150px;
  }
  
  .button {
    background: #2da833;
    color: #fff;
    border: none;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .button:hover {
    background: #41e932;
  }
  
  .button-disabled {
    background: #444;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .contact-form {
      flex-direction: column;
      align-items: center;
      margin-left: -2rem;
    }
  
    .form-container, .contact-info {
      max-width: 100%;
    }
  
    .user {
      padding: 10px;
      font-size: 14px;
    }
  
    .button {
      padding: 10px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {

    .contact-form {
      flex-direction: column;
      align-items: center;
      margin-left: -2rem;
    }

    .user {
      padding: 8px;
      font-size: 12px;
    }
  
    .button {
      padding: 8px;
      font-size: 12px;
    }
  }
  